<template>
  <v-card>
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-step :complete="currentStep > 1" step="1">
        {{ $t('password_policy') }}
      </v-stepper-step>

      <v-stepper-content step="1">
        {{ $t('password_policy_description') }}
        
        <v-form ref="changePassword">
          <v-text-field
            v-model="changePasswordObj.currentPassword"
            :label="$t('current_password')"
            :rules="passwordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="changePasswordObj.newPassword"
            :label="$t('new_password')"
            :rules="newPasswordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model="changePasswordObj.confirmNewPassword"
            :label="$t('confirm_new_password')"
            :rules="confirmationPasswordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-form>
        <v-btn color="primary" @click="navigateStep2()" :disabled="disabledStep2">{{$t('continue')}}</v-btn>  
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">{{$t('confirmation')}}</v-stepper-step>

      <v-stepper-content step="2">
        {{ $t('save_successfully') }}
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
import { DataService } from "../services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      currentStep: "1",
      emailRules: [
        v => !!v || `${this.$t('email')} ${this.$t('required')}`,
        v => /.+@.+\..+/.test(v) || this.$t('email_valid'),
      ],
      passwordRules: [
        value => !!value || `${this.$t('password')} ${this.$t('required')}`,
        value => (value || '').length >= 8 || this.$t('min_characters', { 1: 8 }),
      ],
      newPasswordRules: [
        value => !!value || `${this.$t('new_password')} ${this.$t('required')}`,
        value => (value || '').length >= 8 || this.$t('min_characters', { 1: 8 }),
      ],
      confirmationPasswordRules: [
        value => !!value || this.$t('confirmation_password'),
        value => (value || '').length >= 8 || this.$t('min_characters', { 1: 8 }),
        value => (value === this.changePasswordObj.newPassword) || this.$t('confirmation_password_mismatch'),
      ],
      disabledStep2: false,
      showPassword: false,
      changePasswordObj: {},
    };
  },

  methods: {
    async navigateStep2() {
      if (await this.$refs.changePassword.validate()) {
        this.disabledStep2 = true 

        dataService.changePassword(this.$store.state.token, this.changePasswordObj).then(() => {
          this.currentStep = 2
        }).catch(() => {
          this.disabledStep2 = false
        });
      }
    },

  },
  mounted() {

  }
};
</script>